import Layout from './Layout';
import About from './About';
import CV from './cv';
import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';
import Projets from './Projets';
import Contact from './Contact';
import { useState } from 'react';

const App = props => {

    const routes = [{
        path: '/',
        element: <Layout />,
        children: [{
            element: <About />,
            path: 'about'
        },
        {
            element: <Projets />,
            path: 'projets',
        },
        {
            element: <Contact />,
            path: 'contact'
        },
        {
            element: <CV />,
            path: 'cv'
        },

        ]
    },
    {
        path: '/About',
        element: <About />
    },
    {
        path: '*',
        element: <Navigate to="/about" replace />
    }];


    return (
        <RouterProvider router={createBrowserRouter([routes[0]])} />
    );
};

export default App;
