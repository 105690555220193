import Pdf from './Mathis_Bolduc_Resume.pdf'
import { Link } from 'react-router-dom';
import './cv.css'
const CV = props => {

    return (
        <div>
            <h2>Mon CV</h2>
            <Link to="src\components\Mathis_Bolduc_Resume.pdf" target="_blank" download>Pour enregistrer mon CV!</Link>
            <embed src={Pdf} width="800px" height="1130px" />
        </div>
    );
};

export default CV;