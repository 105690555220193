import { Link, useLocation } from "react-router-dom";
const Nav = ({links}) => {
    const location = useLocation();
    return (
        <nav>
            <ul className="">
                {links.map((link)=>(
                    
                //<li key={link.url}><a href={link.url}>{link.name}</a></li>
                <li className={location.pathname.startsWith(link.url)? 'active' : ''} key={link.url}><Link to={link.url}>{link.name}</Link></li>
                ))}
            </ul>
        </nav>
    );
};

export default Nav;
