import Pdf from './Mathis_Bolduc_Resume.pdf'
const Contact = props => {

    return (
        <div>
            <h2>Contact</h2>
            <p>450-848-9922</p>
            <p>mathisbolduc0@gmail.com</p>
            <a href={Pdf} target="blank">Mon Cv</a>
        </div>
    );
};

export default Contact;
