import Nav from './Nav';
import About from './About';
import Gallery from './Projets';
import Contact from './Contact';
import './Layout.css'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

const Layout = () => {
    const location = useLocation();
    return (
        <div className='tout'>

            <header className="header">
                
                <div className='background'>

                    <Nav links={[
                        { name: 'À propos', url: '/about' },
                        { name: 'Projets', url: '/projets' },
                    ]} />
                    <h1>Mathis Bolduc</h1>
                    <Nav links={[
                        { name: 'Contact', url: '/contact' },
                        { name: 'CV', url: '/cv' },

                    ]} />

                        
                </div>
            </header>
                

                {/* Les différentes vue de l'app seront affichées dans le div ci-dessous */}


            <main>
                {location.pathname == '/' ? <Navigate to="/about" /> : null}
                <Outlet />
            </main>
        </div>
    );
};

export default Layout;