import { Link } from "react-router-dom";
const About = props => {
    let img = [];
    for (let i = 0; i < 12; i++) {
        img.push(i);

    }
    return (
        <>
            <img src={`${process.env.PUBLIC_URL}/assets/atypic1.JPG`} alt="" className='img_header' />
            <div className="main">
                <h2>À propos de moi</h2>
                <div>

                    <h3>Qui je suis</h3>
                    <p>Je suis un finissant en Techniques d’Intégration
                        Multimédia au Cégep de Saint-Jérôme. Je suis aussi un jeune homme souriant et respectueux,
                        j'aime réellement travailler avec de nouvelles
                        personnes. J'aime découvrir ces personnes et
                        surtout apprendre de ces personnes. Afin
                        d'acquérir de nouvelles expérience, je suis
                        travaillant et très ouvert d'esprit face aux
                        différentes expériences.
                    </p>
                </div>
                <div>

                    <h3>Mon projet d'on je suis le plus fière</h3>
                    <h4>Mon premier site avec du 3D</h4>
                    <a href="https://projetfinal-17862.firebaseapp.com" target="_blank">Visitez le!</a>
                    <p className="projet_pref">Ce projet est mon préféré puisqu’il est unique parmi mes autres sites web. Lorsque j’ai commencé ce site, j’étais pratiquement dans le noir total. Mais c’est ce qui m’a plu. J’ai énormément appris et c’est aussi une des raisons pour laquelle c’est mon préféré.
                    </p>
                </div>
                <div>
                    <h3>Expérience professionnel</h3>
                    <div>

                        <h4>Stagiaire chez Atypic et employé pour un contrat d'un mois</h4>
                        <p>Mars à juin 2023, puis de juillet à aout 2023</p>
                        <ul className="b">Ce que j'ai appris :
                            <p></p>
                            <li className="a">J'ai appris à faire le HTML de courriel en utilisant des tables.</li>
                            <li className="a">J'ai appris à faire des pages en utilisant un thème dans Wordpress.</li>
                            <li className="a">J'ai appris à faire le QA des sites en utilisant plusieurs outils.</li>
                            <li className="a">J'ai appris comment travailler avec Wordpress afin de faire des modifications de toutes sortes.</li>
                            <li className="a">J'ai appris à faire le setup d'un nouveau site avec un thème Wordpress.</li>
                            <li className="a">J'ai appris comment mettre en ligne un site Wordpress ayant un domaine et un serveur.</li>
                            <li className="a">J'ai appris à travailler en équipe dans le monde du Web et à utiliser une application de gestion de tâches.</li>
                            <li className="a">J'ai appris à choisir des les bonnes photos afin de passer éfficacement le massage du client.</li>
                        </ul>
                        <p></p>
                        <ul className="b">Ce que j'ai fait en plus d'apprendre :
                            <p></p>
                            <li className="a">J'ai fait le CSS complet de la page de <a href="https://paparmaneodon.ca/" target="_blank">Paperman-o-don</a>. </li>
                            <li className="a">J'ai participé à la sélection de nouvelles photos pour la réforme du site d'<a href="https://www.educalcool.qc.ca/" target="_blank">Éduc'alcool</a>.</li>
                        </ul>
                    </div>
                    <div>

                        <h4>Commis de viandes au Maxi de la Galerie des Laurentides</h4>
                        <p>Avril 2022 à aujourd'hui</p>
                        <ul className="b">Ce que j'ai appris :
                            <p></p>
                            <li className="a">J'ai appris à garder mon sang-froid avec des clients difficiles.</li>
                            <li className="a">J'ai appris à travailler en équipe avec toute sorte de personnes.</li>
                            <li className="a">J'ai aussi appris à bien comprendre les demandes des clients afin de pouvoir répondre à leurs besoins comme il faut.</li>
                        </ul>
                    </div>
                    <div>
                        <h4>Cuisinier au Saint-Hubert de Saint-Jérôme</h4>
                        <p>Novembre 2019 à juin 2021</p>
                        <ul className="b">Ce que j'ai appris :
                            <p></p>
                            <li className="a">J'ai appris l'importance du travaille d'équipe.</li>
                            <li className="a">J'ai appris à travailler en équipe avec toute sorte de personnes.</li>
                            <li className="a">J'ai aussi appris à bien comprendre les demandes des clients afin de pouvoir répondre à leurs besoins comme il faut.</li>
                        </ul>
                    </div>
                </div>
                <div>
                    <h3>Mes compétences</h3>
                    <div className="images">

                        {img.map((i) => (
                            <img key={i} src={`${process.env.PUBLIC_URL}/assets/${i}.png`} alt={i} width="200 px" className={`img${i}`} />
                            //{`/public/img/${i}.png`}
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;
