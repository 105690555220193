const Projets = () => {
    return (
        <>
            <img src={`${process.env.PUBLIC_URL}/assets/atypic1.JPG`} alt="" className='img_header' />
            <div className="main">
                <h2>Mes projets</h2>
                <div>
                <h2>Site web avec du 3D</h2>
                <p>Ce site web sur la mythologie grecque semble normal jusqu’à ce que vous cliquiez Visitez par vous-même. À ce moment, vous êtes transporté directement dans le mont Olympe ou Othrix. Vous pouvez alors explorer par vous-même et apprendre sur le sujet avec du visuel unique. </p>
                <ul className="b">
                    Les choses que j'ai appris
                    <p></p>
                    <li className="a">J'ai appris à utiliser A-frame, un framework qui permet d'intégrer du 3D dans un site.</li>
                    <li className="a">J'ai appris à utiliser la base de donnée de Firebase et de la combiner avec A-frame.</li>
                    <li className="a">J'ai étendu mes connaissances avec TailwindCSS.</li>
                    <li className="a">J'ai appris à utiliser React avec Firebase.</li>
                    <li className="a">J'ai déveloper mes connaissances en design puisque que j'ai tout fait et je n'avais pas d'artiste.</li>
                </ul>
                <p></p>
                <a href="https://projetfinal-17862.firebaseapp.com" target="_blank">Visitez le!</a>
            </div>
                <div>
                    <h3>Site web en PHP</h3>
                    <p>J'ai faites, à l'aide d'un autre programmeur un de 2 artistes, un site web touristique en PHP en utilisant une base de donnée. Le site avait plusieurs pages ainsi qu'un système de favoris. Il était possible de cliquer un bouton afin de l'ajouter aux favoris ensuite on pouvais afficher seulment les favoris. Il y avait aussi une barre de recherche avancé ainsi que affichage aléatoire à l'acceuil. Il était aussi possible de se login</p>
                    <ul className="b">
                        Les choses que j'ai appris en PHP:
                        <p></p>
                        <li className="a">Comment utiliser la base de donnée (modifier, créer, supprimer)</li>
                        <li className="a">Comment créer un système de favoris</li>
                        <li className="a">Comment afficher aléatiorement des élement d'une base de donnée</li>
                        <li className="a">Comment crée une barre de recherche avancée</li>
                        <li className="a">Comment ajouter une page login avec une fonction qui dit si tu est un employé ou non</li>
                        <li className="a">Comment changer de page tout en transportant des variables</li>
                    </ul>
                    <img src={`${process.env.PUBLIC_URL}/assets/web0.JPG`} alt="" width="300 px" />
                    <img src={`${process.env.PUBLIC_URL}/assets/web1.JPG`} alt="" width="300 px" />
                    <img src={`${process.env.PUBLIC_URL}/assets/web2.JPG`} alt="" width="300 px" />

                </div>
                <div>
                    <h3>Premier jeu 2D complet</h3>
                    <p>Mon projet dont je suis le plus fier! C’est un jeu 2D de plateforme. Il est constitué de plusieurs types de salles qui sont généré de façon aléatoire afin de faire un niveau. Il y a 3 niveaux ainsi qu’un Boss. Ma fierté est surtout les ennemis. Ils réagissent à la présence du joueur et ils attaquent seulement quand le joueur est face aux ennemis. Le joueur peut aussi attaquer les ennemis. Ils y a aussi un aimant qui permet d’attirer l’argent. </p>
                    <img src={`${process.env.PUBLIC_URL}/assets/jeu3.JPG`} alt="" width="300 px" />
                    <img src={`${process.env.PUBLIC_URL}/assets/jeu4.JPG`} alt="" width="300 px" />
                    <img src={`${process.env.PUBLIC_URL}/assets/jeu5.JPG`} alt="" width="300 px" />
                    <h4>Comment jouer</h4>
                    <p>Il faut activer la manivelle afin d'activer les bonus, la fée permet d'ouvrir la porte. WASD pour bouger, ESPACE pour sauter et clic gauche de la souris pour attaquer</p>
                    <a href="https://jtremblay.tim-cstj.ca/jeu4/2022/v6/" target="_blank">Essayer le!</a>
                </div>
                <div>
                    <h3>Premier jeu VR</h3>
                    <p>Avec deux coéquipier, nous avons créer une histoire linéaire en VR. C'est une courte histoire où le joueur peut intéragir avec son environnement afin d'en apprendre plus. L'histoire ce passera dans un musée et il y a de boutons qui permettent d'en savoir plus sur l'oeuvre et la personne qui parle. À la fin on doit <b>(Attention Spoilers Alert)</b> volé une des oeuvres et on découvre que nous sommes la personne qui parle dans les speakers.  </p>
                    <img src={`${process.env.PUBLIC_URL}/assets/jeu0.JPG`} alt="" width="300 px" />
                    <img src={`${process.env.PUBLIC_URL}/assets/jeu1.JPG`} alt="" width="300 px" />
                    <img src={`${process.env.PUBLIC_URL}/assets/jeu2.JPG`} alt="" width="300 px" />
                </div>
                <div>
                <h2>Jeu d'ile procédural en 3D</h2>
                <p>J'ai fait ce jeu à l'aide de 2 autres programmeurs et un artiste. C'était notre premier jeu 3D et nous avons tout imaginé. Le but du jeu simplement de passer partout 2 fois afin de faire pousser
                    des fleurs et du gazon. Il y a des obstacles comme le golem et les champignons mais il y a aussi des power-ups comme les ruches. Il y a même un nain qui est notre allié.
                </p>
                {/* <ul>
                    Les choses que j'ai appris
                    <li></li>
                </ul> */}
                <video width="720" height="480" controls>
                <source src={`${process.env.PUBLIC_URL}/assets/demo.mp4`} type="video/mp4"/>
                </video>
            </div>
            </div>
        </>
    );
};

export default Projets;
